<template>
  <f7-popup 
    id="createExpertPopup" ref="expertCreatePopup" class="popup-side popup-save" :tablet-fullscreen="false"
    :backdrop="true" :close-by-backdrop-click="false">
    <f7-page>
      <template #fixed>
        <NavigationPopupSideComponent :title="title" @close-popup="closePopup" />
      </template>

      <f7-block>
        <form ref="expertForm" method="post" class="expert-form" @submit.prevent="submitForm">
          <!-- Account Information Section -->
          <div class="form-section">
            <h3>Account Information</h3>
            <f7-list form>
              <f7-list-input 
                v-model:value="formData.Email" name="Email" :required="true"
                :label="$t.getTranslation('PNL_EMAIL_ADDRESS')" :placeholder="$t.getTranslation('PNL_TYPE_HERE')"
                :info="$t.getTranslation('Please enter Email Address ')" floating-label outline type="text" validate
                clear-button>
              </f7-list-input>
              <f7-list-input 
                v-model:value="formData.Username" name="Username" :required="true"
                :label="$t.getTranslation('PNL_USERNAME')" :placeholder="$t.getTranslation('PNL_TYPE_HERE')"
                :info="$t.getTranslation('Please enter Username')" floating-label outline type="text" validate
                clear-button>
              </f7-list-input>
              <f7-list-input 
                v-model:value="formData.Password" name="Password" :required="true"
                :label="$t.getTranslation('PNL_PASSWORD')" :placeholder="$t.getTranslation('PNL_TYPE_HERE')"
                :info="$t.getTranslation('Please enter a Password')" floating-label outline type="text" validate
                clear-button>
              </f7-list-input>
            </f7-list>
          </div>

          <!-- Primary Information Section -->
          <div class="form-section">
            <h3>Primary Information</h3>
            <f7-list form>

              <f7-list-input 
                v-model:value="formData.FirstName" name="FirstName" :required="true"
                :label="$t.getTranslation('First Name')" :placeholder="$t.getTranslation('PNL_TYPE_HERE')"
                :info="$t.getTranslation('Please enter First Name ')" floating-label outline type="text" validate
                clear-button>
              </f7-list-input>
              <f7-list-input 
                v-model:value="formData.LastName" name="LastName" :required="true"
                :label="$t.getTranslation('Last Name')" :placeholder="$t.getTranslation('PNL_TYPE_HERE')"
                :info="$t.getTranslation('Please enter Last Name ')" floating-label outline type="text" validate
                clear-button>
              </f7-list-input>

              <f7-list-input 
                v-model:value="formData.BirthDate" name="BirthDate" :required="true"
                :label="$t.getTranslation('Birth Date ')" :placeholder="$t.getTranslation('PNL_TYPE_HERE')"
                :info="$t.getTranslation('Please enter Birth Date ')" floating-label outline type="date" validate
                clear-button>
              </f7-list-input>

              <f7-list-input 
                v-model="formData.Gender" label="Gender" :required="true" floating-label outline
                type="select" placeholder="Select gender">
                <option v-for="(item, index) in genderList" :key="index" :value="item.value">
                  {{ item.name }}
                </option>
              </f7-list-input>
              <f7-list-input 
                v-model:value="formData.Bio" name="Bio" :required="true" :label="$t.getTranslation('Bio')"
                :placeholder="$t.getTranslation('PNL_TYPE_HERE')" :info="$t.getTranslation('Please enter Bio')"
                floating-label outline type="textarea" validate clear-button>
              </f7-list-input>
            </f7-list>
          </div>

          <div class="form-section">
            <h3>Contact Information</h3>
            <f7-list form>
              <f7-list-input 
                v-model:value="formData.MobileNumber" name="MobileNumber" :required="true"
                :label="$t.getTranslation('Mobile Number')" :placeholder="$t.getTranslation('PNL_TYPE_HERE')"
                :info="$t.getTranslation('Please enter Mobile Number')" floating-label outline type="text" validate
                clear-button>
              </f7-list-input>

            </f7-list>
            <h3>Status Information</h3>
            <f7-list form>
              <f7-list-input 
                v-model="formData.StatusCode" label="Status" :required="true" floating-label outline
                type="select" placeholder="Select status">
                <option v-for="(item, index) in statusList" :key="index" :value="item.code">
                  {{ item.name }}
                </option>
              </f7-list-input>
            </f7-list>
          </div>
        </form>
      </f7-block>
      <div class="button-fixed-container">
        <f7-button fill type="button" @click.prevent="submitForm">{{ $t.getTranslation('PNL_CREATE') }}</f7-button>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, reactive, inject, ref } from "vue";
import { post } from '@/utils/axios'
import { f7 } from "framework7-vue";
import { useStore } from "@/store";

import NavigationPopupSideComponent from "@/components/NavigationPopupSideComponent.vue";

export default defineComponent({
  name: "ExpertCreateLocationPartial",
  components: {
    NavigationPopupSideComponent,
  },
  props: {
    success: {
      type: Function,
      requied: true
    }
  },
  emits: ['closed'],
  setup() {
    const $t = inject("$translation");
    const store = useStore();

    const title = ref($t.getTranslation("PNL_CREATE_EXPERT"));

    const formData = reactive({
      Email: '',
      Username: '',
      Password: '',
      FirstName: '',
      LastName: '',
      BirthDate: '',
      Gender: '',
      Bio: '',
      MobileNumber: '',
      MobileCode: store.getters["config/getData"]?.defaultMobileCode,
      Image: null,
      StatusCode: 'ACTIVE',
    });



    const genderList = [
      { name: 'Male', value: 'M' },
      { name: 'Female', value: 'F' },
      { name: 'Other', value: 'O' }
    ];

    const statusList = [
      { name: 'Active', code: 'ACTIVE' },
      { name: 'Inactive', code: 'INACTIVE' }
    ];

    return {
      title,
      formData,
      genderList,
      statusList,
    };
  },
  methods: {
    openPopup() {
      Object.assign(this.formData, {
        Email: '',
        Username: '',
        Password: '',
        FirstName: '',
        LastName: '',
        BirthDate: '',
        Gender: '',
        Bio: '',
        MobileNumber: '',
        MobileCode: '',
        Image: null,
        StatusCode: 'ACTIVE',
      });

      if (this.$refs.expertForm) {
        this.$refs.expertForm.reset();
      }
      f7.popup.open("#createExpertPopup");
    },
    closePopup(data) {
      f7.popup.close("#createExpertPopup");
      this.$emit("closed", data);
    },

    async submitForm() {
      try {
        const formPayload = new FormData();
        Object.keys(this.formData).forEach((key) => {
          formPayload.append(key, this.formData[key]);
        });

        const response = await post('/backend/expert/save', formPayload);
        f7.toast.show({ text: this.$t.getTranslation("MSG_SUCCESSFULLY_CREATED_NEW_ITEM") });
        if (response === undefined) {
          f7.toast.show({ text: this.$t.getTranslation("Please fill the all the neccessary fields ") });
        } else {
          this.closePopup(response.data);
          this.success(true)
        }
      } catch (error) {
        console.error(error);
        f7.toast.show({ text: "Please fill the all the neccessary fields ", closeTimeout: 3000 });
      }
    },

  },
});
</script>



<style scoped>
.page-content {
  position: relative;
}

.popup.popup-side,
.page,
.page .page-content {
  background-color: #f1f1f1 !important;
}

.page-content,
.block {
  background-color: #f1f1f1;
  padding: 30px;
  margin: 0;
}

.form-section {
  margin-bottom: 0;
  background: #fff;
  padding: 20px 30px;
  border-radius: 4px;
  height: fit-content;
}

.form-section h3 {
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
}

.image-upload {
  border: 2px dashed #bbb;
  padding: 20px;
  text-align: center;
}

.image-upload p {
  color: #666;
}

.image-requirements {
  font-size: 0.9em;
  color: #999;
}

.expert-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.list ul {
  padding-bottom: 25px;
}

.list ul::before {
  content: unset;
}

.list .item-content {
  padding: 0 !important;
  display: block !important;
}

.list .item-inner {
  display: block !important;
  border: none !important;
}

.button-fixed-container {
  position: fixed;
  left: 0;
  background-color: rgb(255, 255, 255);
  width: 100%;
  z-index: 9;
  bottom: 0;
  padding: 26px 0;
}
.button-fixed-container .button-fill {
  max-width: 400px;
  margin: 0 auto;
} 
.list ul:after {
  bottom: -10px;
  display: none;
}
#createExpertPopup .page-content .block {
  height: calc(100vh - 148px);
  overflow: auto;
}
@media screen and (max-width: 1199px) {

  .form-section {
    padding: 15px;
  }
}

@media screen and (max-width: 1599px) {
  .expert-form {
    grid-template-columns: 1fr;
  }
}
</style>
