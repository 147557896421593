import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'product',
    path: '/product/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'cart-shopping',
        title: translation.getTranslation('PNL_NAV_PRODUCT', 'Product'),
        activeLink: 'product'
      },
      isSecured: true,
      hasRoles: ['SERVICEPARTNER_SERVICE']
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import('@/pages/product/ProductListPage.vue')
  }
]

export default routes
