<template>
    <f7-popup 
    id="createServiceLocationPopup" ref="serviceLocationCreatePopup"
        class="popup-side popup-save createServiceLocationPopup" :tablet-fullscreen="false" :backdrop="true"
        :close-by-backdrop-click="false">
        <f7-page>
            <template #fixed>
                <NavigationPopupSideComponent :title="title" @close-popup="closePopup" />
            </template>

            <f7-block>
                <form ref="serviceLocationForm" method="post" class="expert-form" @submit.prevent="submitForm">
                    <div class="form-section">
                        <h3>Location Information</h3>
                        <f7-list form>
                            <f7-list-input 
                                v-model:value="formData.LocationName" name="LocationName" :required="true"
                                :label="$t.getTranslation('Location Name')"
                                :placeholder="$t.getTranslation('Enter Location Name')" floating-label outline
                                type="text" validate clear-button>
                            </f7-list-input>
                        </f7-list>

                        <h3>Select Expert</h3>
                        <f7-list form class="dropdownList" @mouseleave="closeDropdown" >
                            <f7-button outline style="width: 98%; height: 45px;" @click="toggleDropdown"  > Select Experts
                            </f7-button>
                            <f7-list 
                                v-show="dropdownOpen" class="checkbox-dropdown">
                                <f7-list-item 
                                    v-for="(expert) in expertsList" :key="expert.id"
                                    @click="toggleExpertSelection(expert.ExpertId)">
                                    <div>{{ expert.FirstName }}</div>
                                    <f7-checkbox :checked="formData.ExpertList.includes(expert.ExpertId)"></f7-checkbox>
                                </f7-list-item>
                            </f7-list>
                        </f7-list>

                        <h3>Status Information</h3>
                        <f7-list form>
                            <f7-list-input 
                                v-model="formData.Status" label="Status" floating-label outline type="select"
                                :required="true">
                                <option v-for="(item, index) in statusList" :key="index" :value="item.code">
                                    {{ item.name }}
                                </option>
                            </f7-list-input>
                        </f7-list>
                    </div>

                    <div class="form-section">
                        <h3>Contact Information</h3>
                        <f7-list form>
                            <f7-list-input 
                                v-model:value="formData.FirstName" name="FirstName" :required="true"
                                :label="$t.getTranslation('First Name')"
                                :placeholder="$t.getTranslation('Enter First Name')" floating-label outline type="text"
                                validate clear-button>
                            </f7-list-input>
                            <f7-list-input 
                                v-model:value="formData.LastName" name="LastName" :required="true"
                                :label="$t.getTranslation('Last Name')"
                                :placeholder="$t.getTranslation('Enter Last Name')" floating-label outline type="text"
                                validate clear-button>
                            </f7-list-input>

                            <f7-list-input 
                                v-model:value="formData.MobileNumber" name="MobileNumber" :required="true"
                                :label="$t.getTranslation('Mobile Number')"
                                :placeholder="$t.getTranslation('Enter Mobile Number')" floating-label outline
                                type="text" validate clear-button>
                            </f7-list-input>
                        </f7-list>
                    </div>

                    <div class="form-section">
                        <h3>Address Information</h3>
                        <f7-list form>
                            <f7-list-input 
                                v-model:value="formData.PrimaryAddress" name="PrimaryAddress"
                                :required="true" :label="$t.getTranslation('Primary Address')"
                                :placeholder="$t.getTranslation('Enter Primary Address')" floating-label outline
                                type="text" validate clear-button>
                            </f7-list-input>
                            <f7-list-input 
                                v-model:value="formData.District" name="District" :required="true"
                                :label="$t.getTranslation('District')"
                                :placeholder="$t.getTranslation('Enter District')" floating-label outline type="text"
                                clear-button>
                            </f7-list-input>
                            <f7-list-input 
                                v-model:value="formData.City" name="City" :required="true"
                                :label="$t.getTranslation('City')" :placeholder="$t.getTranslation('Enter City')"
                                floating-label outline type="text" clear-button>
                            </f7-list-input>
                            <f7-list-input 
                                v-model:value="formData.Country" name="Country" :required="true"
                                :label="$t.getTranslation('Country')" :placeholder="$t.getTranslation('Enter Country')"
                                floating-label outline type="text" validate clear-button>
                            </f7-list-input>
                            <f7-list-input 
                                v-model:value="formData.PostalCode" :required="true" name="PostalCode"
                                :label="$t.getTranslation('Postal Code')"
                                :placeholder="$t.getTranslation('Enter Postal Code')" floating-label outline type="text"
                                clear-button>
                            </f7-list-input>
                        </f7-list>
                    </div>
                </form>
            </f7-block>
            <div class="button-fixed-container">
                <f7-button :fill="isFormChanged" type="button" @click.prevent="submitForm">
                    {{ $t.getTranslation('PNL_CREATE') }}
                </f7-button>
            </div>
        </f7-page>
    </f7-popup>
</template>

<script>
import { defineComponent, reactive, inject, ref, watch, onMounted } from "vue";
import { get, post } from '@/utils/axios';
import { f7 } from "framework7-vue";
import { useStore } from "@/store";
const store = useStore();

import NavigationPopupSideComponent from "@/components/NavigationPopupSideComponent.vue";

export default defineComponent({
    name: "ServiceLocationCreatePartial",
    components: {
        NavigationPopupSideComponent,
    },
    props: {
        success: {
            type: Function,
            required: true
        }
    },
    setup(props) {
        const $t = inject("$translation");
        const title = ref($t.getTranslation("Create Service Partner Location"));
        const userInfo = store.getters["user/getData"];

        const formData = reactive({
            LocationName: '',
            GeoLocation: '',
            ExpertList: [],
            FirstName: '',
            LastName: '',
            MobileNumber: '',
            PrimaryAddress: '',
            District: '',
            City: '',
            Country: '',
            PostalCode: '',
            Status: 'ACTIVE',
        });

        const initialFormData = JSON.parse(JSON.stringify(formData));

        const statusList = [
            { name: 'Active', code: 'ACTIVE' },
            { name: 'Inactive', code: 'INACTIVE' }
        ];

        const expertsList = ref([]);

        const dropdownOpen = ref(false);

        const isFormChanged = ref(false);

        watch(formData, () => {
            isFormChanged.value = JSON.stringify(formData) !== JSON.stringify(initialFormData);
        }, { deep: true });

        onMounted(async () => {
            await fetchExperts();
        });

        const fetchExperts = async () => {

            try {
                const response = await get(`/public/expert/datalist?ServicePartnerId=${userInfo.ServicePartnerId}`);
                console.log(response, "expertsList response");
                if (response && response.data) {
                    expertsList.value = response.data;
                }

            } catch (error) {
                console.error('Failed to fetch experts', error);
                f7.toast.show({ text: 'Failed to load experts', closeTimeout: 3000 });
            }
        };

        // const fetchServicepartnerlocation = async () => {
        //     try {
        //         const response = await get(`public/servicepartnerlocation/datalist?ServicePartnerId=${userInfo.ServicePartnerId}`);
        //         if (response && response.data) {
        //         }
        //     } catch (error) {
        //         console.error('Failed to fetch experts', error);
        //         f7.toast.show({ text: 'Failed to load experts', closeTimeout: 3000 });
        //     }
        // };


        const openPopup = () => {
            Object.assign(formData, {
                LocationName: '',
                GeoLocation: '',
                ExpertList: [],
                FirstName: '',
                LastName: '',
                MobileNumber: '',
                PrimaryAddress: '',
                District: '',
                City: '',
                Country: '',
                PostalCode: '',
                Status: 'ACTIVE',
            });


            f7.popup.open("#createServiceLocationPopup");
        };

        const closePopup = () => {
            f7.popup.close("#createServiceLocationPopup");
        };

        const toggleDropdown = () => {
            dropdownOpen.value = !dropdownOpen.value;
        };

        const toggleExpertSelection = (expertId) => {
            const existingExpert = formData.ExpertList.find(expert => expert.ExpertId === expertId);

            if (existingExpert) {
                formData.ExpertList = formData.ExpertList.filter(expert => expert.ExpertId !== expertId);
            } else {
                formData.ExpertList.push({
                    ExpertId: expertId,
                    Position: formData.ExpertList.length + 1
                });
            }
            formData.ExpertList = formData.ExpertList.map((expert, index) => ({
                ExpertId: expert.ExpertId,
                Position: index + 1,
            }));
        };


        const closeDropdown = () => {
            dropdownOpen.value = false;
        };

        const submitForm = async () => {
            try {
                const formPayload = {
                    LocationName: formData.LocationName,
                    FirstName: formData.FirstName,
                    LastName: formData.LastName,
                    MobileCode: "+91",
                    MobileNumber: formData.MobileNumber,
                    ContactNumber: "",
                    PrimaryAddress: formData.PrimaryAddress,
                    District: formData.District,
                    City: formData.City,
                    Country: formData.Country,
                    PostalCode: formData.PostalCode,
                    StatusCode: formData.Status,
                    ExpertList: formData.ExpertList,
                    GeoLocationList: [],
                    CountryId: "99",
                    IsDefault: 1
                };

                const response = await post('backend/servicepartnerlocation/save', formPayload);
                if (response.isInserted === true) {
                    closePopup(response.data)
                    // fetchServicepartnerlocation()

                    props.success(true)
                    console.log(props.success);
                    f7.toast.show({ text: 'Service location created successfully', closeTimeout: 3000 });
                }
            } catch (error) {
                console.error('Failed to create service location', error);
                f7.toast.show({ text: 'Failed to create service location', closeTimeout: 3000 });
            }
        };

        return {
            title,
            formData,
            statusList,
            expertsList,
            dropdownOpen,
            isFormChanged,
            $t,
            openPopup,
            closePopup,
            toggleDropdown,
            toggleExpertSelection,
            submitForm,
            closeDropdown
        };
    }
});
</script>


<style scoped>
.page-content {
    position: relative;
}

.popup.popup-side,
.page,
.page .page-content {
    background-color: #f1f1f1 !important;
}

.page-content,
.block {
    background-color: #f1f1f1;
    padding: 30px;
    margin: 0;
}

.form-section {
    margin-bottom: 0;
    background: #fff;
    padding: 20px 30px;
    border-radius: 4px;
    height: fit-content;
}

.form-section h3 {
    margin-bottom: 10px;
    font-weight: bold;
    color: #333;
}

.image-upload {
    border: 2px dashed #bbb;
    padding: 20px;
    text-align: center;
}

.image-upload p {
    color: #666;
}

.image-requirements {
    font-size: 0.9em;
    color: #999;
}

.expert-form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.list ul {
    padding-bottom: 25px;
}

.list ul::before {
    content: unset;
}

.list .item-content {
    padding: 0 !important;
    display: block !important;
}

.list .item-inner {
    display: block !important;
    border: none !important;
}

.button-fixed-container {
    position: fixed;
    left: 0;
    background-color: rgb(255, 255, 255);
    width: 100%;
    z-index: 9;
    bottom: 0;
    padding: 26px 0;
}

.button-fixed-container .button-fill {
    max-width: 400px;
    margin: 0 auto;
}

.createServiceLocationPopup .page-content .block {
    height: calc(100vh - 148px);
    overflow: auto;
}

.dropdownList {
    padding: 12px;
}

@media screen and (max-width: 991px) {
    .form-section {
        padding: 15px;
    }
}

@media screen and (max-width: 1599px) {
    .expert-form {
        grid-template-columns: 1fr;
    }
}
</style>